<template>
  <div id="form">
    <br><br>
    <el-form
      :ref="formName"
      :model="query"
      :rules="queryRule"
      label-width="120px"
      label-position="left">
      <el-form-item
        label="彩种"
        align="left"
        prop="lotterycode">
        <el-select v-model="query.lotterycode" placeholder="请选择">
          <el-option
            v-for="item in lotterList"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        label="排序"
        prop="sort"
        align="left">
        <el-input-number style="width: 200px;" :min="1" v-model="query.sort" />
      </el-form-item>
    </el-form>
    <div
      slot="footer"
      class="dialog-footer">
      <el-button @click="handleCancel">
        取消
      </el-button>
      <el-button
        type="primary"
        :loading="saveLoading"
        @click="handleSubmit">
        确认
      </el-button>
    </div>
  </div>
</template>

<script>
import resStatus from '@/constants/resStatus'
import { settingLotterList, settingLotteryAdd } from '@/api/admin/setting'

export default {
  data () {
    return {
      formName: 'form',
      saveLoading: false,
      lotterList: [],
      query: {
        sort: null,
        lotterycode: null,
        is_visible: 1
      },
      queryRule: {
        sort: [
          {
            required: true,
            message: '请输入排序數字',
            trigger: 'blur'
          }
        ],
        lotterycode: [
          {
            required: true,
            message: '请选择彩种',
            trigger: 'blur'
          }
        ]
      }
    }
  },
  created () {
    this.init()
  },
  methods: {
    init () {
      settingLotterList().then(res => {
        switch (res.status) {
          case resStatus.OK:
            var newArr = res.data.map(res => {
              return { value: res.lotterycode, label: res.lotteryname }
            })
            this.lotterList = newArr
            break
          default:
            this.$message.error(res.message)
        }
      }).catch(err => {
        this.$message.error(err)
      })
    },
    handleSubmit () {
      this.$refs[this.formName].validate(valid => {
        if (valid)
          this.saveData()
      })
    },
    handleCancel () {
      this.$router.go(-1)
    },
    saveData () {
      this.saveLoading = true
      settingLotteryAdd(this.query, 'predict')
        .then(res => {
          this.saveLoading = false
          switch (res.status) {
            case resStatus.OK:
              this.$router.go(-1)
              break
          }
          if (res.message.length !== 0) {
            this.$message(res.message)
          }
        })
        .catch(err => {
          this.saveLoading = false
          this.$message.error(err)
        })
    }
  }
}
</script>
